.order-date-input {
  background-color: transparent;
  text-align: center;
  width: 7.5vw;
  color: var(--black, #414346);
  font-family: Noto Sans KR;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: 28px; /* 140% */
  letter-spacing: -0.1px;
}
