@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  font-family:
    "Pretendard Variable",
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    "Open Sans",
    "Helvetica Neue",
    sans-serif;

  box-sizing: border-box;
}

@media screen and (max-width: 1600px) {
  html {
    font-size: 14px;
  }
}

@media screen and (max-width: 1400px) {
  html {
    font-size: 12px;
  }
}

body {
  @apply bg-custom-white;
}

@layer base {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

@layer components {
  .custom-border-container {
    @apply rounded-[8px] border border-solid border-custom-mediumGray;
  }
}
